import logo from './logo.svg';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./page/Home/Home";

function App() {
  return (
    <Router>
    <Switch>
    <Route path="/">
        <HomePage />
      </Route>
    </Switch>
    </Router>
  );
}

export default App;
