import "../../App.css";
import "./home.sass";
import {
  BusinessCenter,
  Circle,
  Code,
  Commit,
  CssOutlined,
  GitHub,
  Html,
  Javascript,
  Laptop,
  Link,
  LinkedIn,
  Mail,
  Map,
  Monitor,
  OpenInNew,
  Php,
  Place,
  Rocket,
  StackedBarChart,
  Star,
  Terminal,
  Twitter,
} from "@mui/icons-material";

function Home() {
  return (
    <div className="App">
      <section className="App-header">
        <div className="header_container">
          <img src="./image.jpg" loading="lazy" />
          <div className="info_column">
            <h3>Adejugbagbe Oluwaseyi</h3>
            <span>Lead Software Engineer </span>
            <div className="sub_rel">
              <span>
                <Place style={{ marginRight: 5 }} /> FCT, Nigeria
              </span>
              <span>
                <Terminal style={{ marginRight: 5 }} /> 5+ Years Experience
              </span>
              <span>
                <BusinessCenter style={{ marginRight: 5 }} /> Developer Advocate
              </span>
            </div>
          </div>
        </div>
        <hr />
      </section>
      <section className="head_container">
        <h5 className="title">Contact</h5>
        <div className="handles_row">
          {/* <a className="social">
            {" "}
            <Mail style={{ marginRight: 5 }} /> Mail
          </a> */}
          <a href="https://twitter.com/SeyiAdejugbagbe" className="social">
            {" "}
            <Twitter style={{ marginRight: 5 }} /> Twitter
          </a>
          <a href="https://github.com/ziggybaba1" className="social">
            <GitHub style={{ marginRight: 5 }} /> GitHub
          </a>
          <a
            href="https://stackoverflow.com/users/10384738/ziggybaba"
            className="social"
          >
            <StackedBarChart style={{ marginRight: 5 }} /> StackOverflow
          </a>
          <a
            href="https://www.linkedin.com/in/seyi-adejugbagbe-2a0512114/"
            className="social"
          >
            <LinkedIn style={{ marginRight: 5 }} /> LinkedIn
          </a>
        </div>
      </section>
      <section className="about_container">
        <h5 className="title">About</h5>
        <div className="handles_column">
          <span className="intro_text">
            Hey &nbsp; &nbsp;
            <img
              src="./icons/emojione_waving-hand-medium-light-skin-tone.png"
              className="icon"
            />
            , &nbsp;I'm <b>&nbsp;&nbsp;Oluwaseyi</b>
          </span>
          <span className="title_change">
            <Laptop style={{ margin: 5 }} />{" "}
          </span>
          <span className="subtitle">
            I am self taught <Rocket style={{ margin: 5,color:'' }} />
          </span>
          <span className="subtitle">
              I love talking and writing about Web development & Software
              Development in general
          </span>
          <span className="subtitle">
            I've been programming for more than ten years and working
            professionally for more than five years as a Lead Software Engineer. An Experienced
            Scrum master and cloud practitioner with a keen ability to
            understand and build complex systems. Passionate and versatile software 
            professional skilled in developing robust applications, 
            leading Agile teams, and architecting scalable solutions on the Cloud. 
            With a solid background in software development and a strong 
            understanding of Agile methodologies, I excel at delivering high-quality 
            software products while ensuring efficient project management and seamless 
            collaboration. 
            I understand the needs and goals of the organization/individual and
            can find ways to help them realize their objectives more
            efficiently. I care for your time and mine also. I'm flexible enough
            to adjust in your requirements ensuring you the quality work
          </span>
        </div>
      </section>
      <hr />
      <section className="tech_container">
        <h5 className="title">Tech Stack</h5>
        <div className="handles_row">
          <a className="stacks">
            <img src="./icons/javascript.png" loading="lazy" />
            Javascript <em>Expert</em>
          </a>
          <a className="stacks">
            <img src="./icons/php.png" loading="lazy" />
            PHP <em>Expert</em>
          </a>
          <a className="stacks">
            <img src="./icons/react.svg" loading="lazy" />
            REACT NATIVE <em>Expert</em>
          </a>
          <a className="stacks">
            <img src="./icons/react.svg" loading="lazy" />
            REACT <em>Expert</em>
          </a>
          <a className="stacks">
            <img src="./icons/nodejs.png" loading="lazy" />
            NODE JS <em>Mid Level</em>
          </a>
          {/* <a className="stacks">
            <img src="./icons/python.png" loading="lazy" />
            Python <em>Mid Level</em>
          </a> */}
          {/* <a className="stacks">
            <img src="./icons/csharp.png" loading="lazy" />
            c# <em>Mid Level</em>
          </a> */}
          {/* <a className="stacks">
            <img src="./icons/nuxtjs.png" loading="lazy" />
            Nuxt JS <em>Mid Level</em>
          </a> */}
          {/* <a className="stacks">
            <img src="./icons/xamarin-logo.png" loading="lazy" />
            Xamarin <em>Junior</em>
          </a> */}
          {/* <a className="stacks">
            <img src="./icons/nextjs.png" loading="lazy" />
            Next JS
          </a> */}
          <a className="stacks">
            <img src="./icons/html.png" loading="lazy" />
            HTML5
          </a>
          <a className="stacks">
            <img src="./icons/sass.png" loading="lazy" />
            SASS
          </a>
          <a className="stacks">
            <img src="./icons/css.webp" loading="lazy" />
            CSS 3
          </a>

          <a className="stacks">
            <img src="./icons/aws.png" loading="lazy" />
            AWS
          </a>
          <a className="stacks">
            <img src="./icons/aws_cloud_formation.png" loading="lazy" />
            AWS Cloud Formation
          </a>
          <a className="stacks">
            <img src="./icons/lamda.webp" loading="lazy" />
            AWS Lamda
          </a>
          <a className="stacks">
            <img src="./icons/DynamoDB.png" loading="lazy" />
            DynamoDB
          </a>
          {/* <a className="stacks">
            <img src="./icons/graphql.png" loading="lazy" />
            GraphQL
          </a> */}
          <a className="stacks">
            <img src="./icons/mysql.png" loading="lazy" />
            MySQL
          </a>
          <a className="stacks">
            <img src="./icons/android.png" loading="lazy" />
            Android Deployment
          </a>
          <a className="stacks">
            <img src="./icons/apple.webp" loading="lazy" />
            Apple Deployment
          </a>
          <a className="stacks">
            <img src="./icons/Git-Icon-1788C.png" loading="lazy" />
            Git
          </a>
          <a className="stacks">
            <img src="./icons/scrum.png" loading="lazy" />
            Scrum Master
          </a>
          <a className="stacks">
            <img src="./icons/laravel.png" loading="lazy" />
            Laravel
          </a>
          <a className="stacks">
            <img src="./icons/strapi.png" loading="lazy" />
            Strapi
          </a>
          <a className="stacks">
            <img src="./icons/firebase.png" loading="lazy" />
            Firebase
          </a>
          {/* <a className="stacks">
            <img src="./icons/visual_studio.svg" loading="lazy" />
            Visual Studio
          </a> */}
          <a className="stacks">
            <img src="./icons/studio_code.png" loading="lazy" />
            Visual Studio Code
          </a>
        </div>
      </section>
      <hr />
      <section className="position_container">
        <h5 className="title">Positions</h5>
        <div className="handles_column">
          {/* <a className="company_row">
            <img src="./icons/niss.png" loading="lazy" />
            <div className="title_col">
              <span className="role">ICT Consultant</span>
              <span className="institute">NISS - October 2020 - Present</span>
            </div>
          </a> */}
          <a className="company_row">
            <img src="./icons/apex.png" loading="lazy" />
            <div className="title_col">
              <span className="role">Lead Software Engineer</span>
              <span className="institute">
                Apex Lonack Technology - October 2021 - Present
              </span>
            </div>
          </a>
          <a className="company_row">
            <img src="./icons/paynergy.png" loading="lazy" />
            <div className="title_col">
              <span className="role">Lead Software Engineer</span>
              <span className="institute">
                Paynergy - February 2018 - March 2021
              </span>
            </div>
          </a>
        </div>
      </section>
      <section className="position_container">
        <h5 className="title">Credentials</h5>
        <div className="handles_column">
          <a className="company_row">
            <img src="./icons/udemy.svg" loading="lazy" />
            <div className="title_col">
              <span className="role">AWS Certified Developer Associate</span>
              <span className="institute">
                Udemy - October 2022 - No Expiration
              </span>
            </div>
          </a>
          <a className="company_row">
            <img src="./icons/fcc_secondary_small.svg" loading="lazy" />
            <div className="title_col">
              <span className="role">Responsive Web design </span>
              <span className="institute">
                FreecCodeCamp - October 2022 - No Expiration
              </span>
            </div>
          </a>
          <a className="company_row">
            <img src="./icons/fcc_secondary_small.svg" loading="lazy" />
            <div className="title_col">
              <span className="role">Scientific Computing using Python </span>
              <span className="institute">
                FreecCodeCamp - October 2022 - No Expiration
              </span>
            </div>
          </a>
          <a className="company_row">
            <img src="./icons/udemy.svg" loading="lazy" />
            <div className="title_col">
              <span className="role">
                The Complete Junior to Senior Web Developer Roadmap
              </span>
              <span className="institute">
                Udemy - October 2021 - No Expiration
              </span>
            </div>
          </a>
          <a className="company_row">
            <img src="./icons/scrum.png" loading="lazy" />
            <div className="title_col">
              <span className="role">Professional Scrum Master</span>
              <span className="institute">
                Scrum Institute - October 2021 - No Expiration
              </span>
            </div>
          </a>
        </div>
      </section>
      <section className="repo_container">
        <h5 className="title">Repositories</h5>
        <div className="repo_row">
          <div className="repo_item">
            <div className="upper">
              <Monitor style={{ marginRight: 10 }} />
              <a href="https://github.com/ziggybaba1/lakeshore">Lakeshore</a>
            </div>
            <span className="describe">E-Learning Dashboard design</span>
            <div className="footer">
              <span>
                <Circle
                  fontSize="small"
                  style={{ color: "#df5b3b", marginRight: 5 }}
                />{" "}
                Javascript
              </span>
              <span>
                <Star /> 0
              </span>
              <span>
                <Commit /> 0
              </span>
            </div>
          </div>
          <div className="repo_item">
            <div className="upper">
              <Monitor style={{ marginRight: 10 }} />
              <a href="https://github.com/ziggybaba1/Python-Scientific-Computing">
                Python-Scientific Computing
              </a>
            </div>
            <span className="describe">
              My Journey to Learning python programming
            </span>
            <div className="footer">
              <span>
                <Circle
                  fontSize="small"
                  style={{ color: "#219cd4", marginRight: 5 }}
                />{" "}
                Python
              </span>
              <span>
                <Star /> 0
              </span>
              <span>
                <Commit /> 0
              </span>
            </div>
          </div>
          <div className="repo_item">
            <div className="upper">
              <Monitor style={{ marginRight: 10 }} />
              <a href="https://github.com/ziggybaba1/BegoXchange">
                BegoXchange
              </a>
            </div>
            <span className="describe">
              NFT Market place mobile application design
            </span>
            <div className="footer">
              <span>
                <Circle
                  fontSize="small"
                  style={{ color: "#df5b3b", marginRight: 5 }}
                />{" "}
                React Native
              </span>
              <span>
                <Star /> 0
              </span>
              <span>
                <Commit /> 0
              </span>
            </div>
          </div>
          <div className="repo_item">
            <div className="upper">
              <Monitor style={{ marginRight: 10 }} />
              <a href="https://github.com/ziggybaba1/MediaCatalog-Backend">
                Media Catalog
              </a>
            </div>
            <span className="describe">Media catalogs backend design</span>
            <div className="footer">
              <span>
                <Circle
                  fontSize="small"
                  style={{ color: "#dad01f", marginRight: 5 }}
                />{" "}
                PHP
              </span>
              <span>
                <Star /> 0
              </span>
              <span>
                <Commit /> 0
              </span>
            </div>
          </div>
          <div className="repo_item">
            <div className="upper">
              <Monitor style={{ marginRight: 10 }} />
              <a href="https://github.com/ziggybaba1/TubeFlix_Client_Server-">
                TubeFlix
              </a>
            </div>
            <span className="describe">Movie streaming backend design</span>
            <div className="footer">
              <span>
                <Circle
                  fontSize="small"
                  style={{ color: "#21bf56", marginRight: 5 }}
                />{" "}
                Node JS
              </span>
              <span>
                <Star /> 0
              </span>
              <span>
                <Commit /> 0
              </span>
            </div>
          </div>
          <div className="repo_item">
            <div className="upper">
              <Monitor style={{ marginRight: 10 }} />
              <a href="https://github.com/ziggybaba1/CryptoTrading">
                CryptoTrading
              </a>
            </div>
            <span className="describe">Simple Smart Contract</span>
            <div className="footer">
              <span>
                <Circle
                  fontSize="small"
                  style={{ color: "#4818d8", marginRight: 5 }}
                />{" "}
                Solidity
              </span>
              <span>
                <Star /> 0
              </span>
              <span>
                <Commit /> 0
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
